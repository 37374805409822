
import { createVNode } from "vue";
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  PlusOutlined
} from "@ant-design/icons-vue";
import Editor from "./compontent/editor/editor.vue";
import Detail from "./compontent/detail/detail.vue";

export default {
  name: "AccountChargeGroup",
  components: {
    DeleteOutlined,
    PlusOutlined,
    Editor,
    Detail
  },
  data() {
    return {
      url: "/admin/charge_group/list",
      // 表格搜索条件
      where: {},
      // 表格选中数据
      selection: [],
      // 当前编辑数据
      current: null,
      // 是否显示编辑弹窗
      showEdit: false,
      // 是否显示费用详情弹窗
      showDetail: false
    };
  },
  computed: {
    columns() {
      return [
        {
          key: "index",
          width: 48,
          customRender: ({ index }) => this.$refs.table.tableIndex + index
        },
        {
          title: this.$t("名称"),
          dataIndex: "name",
          sorter: true,
          ellipsis: true
        },
        {
          title: this.$t("开始时间"),
          dataIndex: "begin_time",
          width: 150,
          sorter: true,
          align: "center"
        },
        {
          title: this.$t("结束时间"),
          dataIndex: "end_time",
          width: 150,
          sorter: true,
          align: "center"
        },
        {
          title: this.$t("材积率"),
          dataIndex: "volume_fraction",
          width: 120,
          sorter: true,
          align: "center"
        },
        {
          title: this.$t("折扣"),
          dataIndex: "discount",
          width: 120,
          sorter: true,
          align: "center",
          customRender: record => `${(record.text * 100).toFixed(2)}%`
        },
        {
          title: this.$t("服务费"),
          dataIndex: "service_fee",
          width: 120,
          sorter: true,
          align: "center",
          customRender: record => `${record.text * 100}%`
        },
        {
          title: this.$t("状态"),
          width: 120,
          align: "center",
          slots: {
            customRender: "active_status"
          }
        },
        {
          title: this.$t("默认"),
          dataIndex: "is_default",
          width: 100,
          sorter: true,
          align: "center",
          slots: {
            customRender: "is_default"
          }
        },
        {
          title: this.$t("启用"),
          dataIndex: "enabled",
          width: 100,
          sorter: true,
          align: "center",
          slots: {
            customRender: "status"
          }
        },
        {
          title: this.$t("创建时间"),
          dataIndex: "created",
          width: 100,
          sorter: true
        },
        {
          title: this.$t("操作"),
          key: "action",
          width: 250,
          align: "center",
          fixed: "right",
          slots: {
            customRender: "action"
          }
        }
      ];
    }
  },
  methods: {
    /* 搜索 */
    reload() {
      this.selection = [];
      this.$refs.table.reload({
        page: 1
      });
    },
    /*  重置搜索 */
    reset() {
      this.where = {};
      this.$nextTick(() => {
        this.reload();
      });
    },
    /**
     * 新建
     */
    createNew() {
      this.current = null;
      this.showEdit = true;
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.current = row;
      this.showEdit = true;
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading("请求中...", 0);

      this.$http
        .post("/admin/charge_group/delete", {
          ids: [row.id]
        })
        .then(res => {
          hide();
          if (res.data.code === 0) {
            this.$message.success(res.data.msg);
            this.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(e => {
          hide();
          this.$message.error(e.message);
        });
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        return this.$message.error("请至少选择一条数据");
      }

      this.$confirm({
        title: this.$t("提示"),
        content: this.$t("确定要删除选中的数据吗?"),
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading("请求中...", 0);

          this.$http
            .post("/admin/charge_group/delete", {
              ids: this.selection.map(d => d.id)
            })
            .then(res => {
              hide();
              if (res.data.code === 0) {
                this.$message.success(res.data.msg);
                this.reload();
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch(e => {
              hide();
              this.$message.error(e.message);
            });
        }
      });
    },
    /* 修改状态 */
    editState(checked, row, field) {
      this.$http
        .get(`/admin/charge_group/state/${row.id}?${field}=${checked ? 1 : 2}`)
        .then(res => {
          if (res.data.code === 0) {
            row[field] = checked;
            /*
                    checked && (field === 'is_default') && this.$refs.table.data.forEach((record) => {
                        if (record.id != row.id) {
                            record[field] = !checked;
                        }
                    });
                    */
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(e => {
          this.$message.error(e.message);
        });
    },
    /* 检查费用组是否启用 */
    check_record_active(record) {
      var begin_time = new Date(record.begin_time).getTime();
      var end_time = new Date(record.end_time).getTime();
      var now = new Date().getTime();

      return record.enabled && now >= begin_time && now < end_time;
    },
    /* 打开详情 */
    openDetail(row) {
      this.current = row;
      this.showDetail = true;
    },
    /* 复制 */
    copy(record) {
      const hide = this.$message.loading("请求中...", 0);

      this.$http
        .get(`/admin/charge_group/copy/${record.id}`)
        .then(res => {
          hide();

          if (res.data.code === 0) {
            this.$message.success("share.copy_success");
            this.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(e => {
          hide();
          this.$message.error(e.message);
        });
    }
  }
};
